<template>
  <div class="enterprise" id="enterprise">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'enterprise'
}
</script>

<style scoped>
.enterprise {
  width: 100%;
  height: 100%;
}
</style>
